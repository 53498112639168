import { Button, Label, TextInput } from 'flowbite-react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-100x100.png';
import { useState } from 'react';
import { HiMail } from 'react-icons/hi';
import { AiOutlineLoading } from 'react-icons/ai';



const ForgotPassword = () => {

    const [email, setEmail] = useState('')

    const [loading, setLoading] = useState(false)

    const forgotPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    return (
        <div className='flex items-center justify-center pt-32 bg-gray'>
            <div className="max-w-xl w-full">
                <Link to="/">
                    <div className='flex justify-center align-middle pb-10'>
                        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
                        <span className="self-center whitespace-nowrap text-xl font-chakra">EasyLink</span>
                    </div>
                </Link>
                <form className="flex flex-col max-w-xl p-2 md:p-10 lg:p-10 border rounded-lg shadow-lg gap-5 font-grotesk" onSubmit={(e) => forgotPassword(e)}>
                    <div className='pb-3 border-b'>
                        <h4 className='text-xl text-bold'>Mot de passe oublié !</h4>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="email" value="Adresse e-mail" />
                        </div>
                        <TextInput id="email" type="email" placeholder="name@gmail.com" rightIcon={HiMail} required shadow value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <Button
                        type="submit"
                        isProcessing={loading}
                        processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
                        disabled={loading}
                    >Récuperer mon compte</Button>

                    <p className="text-gray-500 text-right"><Link to="/login" className="inline-flex items-center font-medium text-cyan-600 dark:text-cyan-500 hover:underline">
                        Se connecter
                        <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                    </Link></p>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;