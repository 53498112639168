import { Button } from "flowbite-react";
import WelcomeMessage from "../../components/Welcome";
import { useAuth } from "../../utils";
import { HiOutlineRefresh } from "react-icons/hi";
import { AiOutlineLoading } from 'react-icons/ai';
import CreateNewLink from './Create'

import { useEffect, useState } from "react";
import LinkList from "./LinkList";
import UpdateLink from "./Update";
import Confirmation from "./Confirmation";
import { get, post } from "../../axiosConfig"
import { toast } from "react-toastify"





const Dashboard = () => {
  const { user } = useAuth();
  const staticLinks = [
    {
      "id": "657415eaf830dac8936ab7da",
      "original_url": "https://yemalin.tech",
      "short_url": "SB4fk54S",
      "user": "string",
      "enable": true,
      "created_at": "2023-12-09T08:23:22.364000",
      "updated_at": "2023-12-09T08:23:22.364000",
      "last_click": "2023-12-09T08:32:20.287000",
      "clicks": 2
    },
    {
      "id": "65765cc6ee19c57f2a83bd39",
      "original_url": "https://google.tech",
      "short_url": "MT06Vheb",
      "user": "string",
      "enable": false,
      "created_at": "2023-12-11T01:50:14.054000",
      "updated_at": "2023-12-11T01:50:14.054000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "65765ccbee19c57f2a83bd3b",
      "original_url": "https://google.org",
      "short_url": "kM8RZoUQ",
      "user": "string",
      "enable": false,
      "created_at": "2023-12-11T01:50:19.548000",
      "updated_at": "2023-12-11T01:50:19.548000",
      "last_click": null,
      "clicks": 0
    }, {
      "id": "657b042f5c36cb3db1e6fe7a",
      "original_url": "https://example1.com",
      "short_url": "xY9PqR8J",
      "user": "string",
      "enable": true,
      "created_at": "2023-12-12T09:30:42.123000",
      "updated_at": "2023-12-12T09:30:42.123000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b04515c36cb3db1e6fe7b",
      "original_url": "https://example2.com",
      "short_url": "aB5ZrM2W",
      "user": "string",
      "enable": true,
      "created_at": "2023-12-12T09:31:21.456000",
      "updated_at": "2023-12-12T09:31:21.456000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b045e5c36cb3db1e6fe7c",
      "original_url": "https://example3.com",
      "short_url": "cV8NjT7F",
      "user": "string",
      "enable": true,
      "created_at": "2023-12-12T09:31:58.789000",
      "updated_at": "2023-12-12T09:31:58.789000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b046b5c36cb3db1e6fe7d",
      "original_url": "https://example4.com",
      "short_url": "hX3KgU1L",
      "user": "string",
      "enable": true,
      "created_at": "2023-12-12T09:32:35.012000",
      "updated_at": "2023-12-12T09:32:35.012000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b04785c36cb3db1e6fe7e",
      "original_url": "https://example5.com",
      "short_url": "mR6FsE4Q",
      "user": "string",
      "enable": false,
      "created_at": "2023-12-12T09:33:11.234000",
      "updated_at": "2023-12-12T09:33:11.234000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b04855c36cb3db1e6fe7f",
      "original_url": "https://example6.com",
      "short_url": "pQ9VhY5Z",
      "user": "string",
      "enable": false,
      "created_at": "2023-12-12T09:33:48.567000",
      "updated_at": "2023-12-12T09:33:48.567000",
      "last_click": null,
      "clicks": 0
    },
    {
      "id": "657b04915c36cb3db1e6fe80",
      "original_url": "https://example7.com",
      "short_url": "sB2XnA3D",
      "user": "string",
      "enable": false,
      "created_at": "2023-12-12T09:34:25.890000",
      "updated_at": "2023-12-12T09:34:25.890000",
      "last_click": null,
      "clicks": 0
    }
  ]
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [links, setLinks] = useState(staticLinks);


  const fetchData = async () => {
    await get("/url/all")
      .then((res) => {
        setLoading(false);
        setLinks(res.data)
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite.")
      })
  };

  const handleUpdateLink = (link) => {
    setSelectedLink(link);
    setOpenUpdateModal(true);
  };

  const refreshLinks = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const createLink = async ({ originalUrl, shortUrl }) => {
    setLinkLoading(true);

    const data = { original_url: originalUrl };

    if (shortUrl) {
      data.short_url = shortUrl;
    }
    await post("/url/shorten", data)
    .then((res) => {
      toast.success(`Lien court ${res.data.short_url} créé.`)
      setLinkLoading(false);
      setOpenCreateModal(false);
      fetchData();
    })
    .catch(() => {
      toast.error("Une erreur s'est produite.")
      setLinkLoading(false);
    })
  };

  const updateLink = ({ originalUrl, shortUrl }) => {
    console.log(originalUrl, shortUrl);
    setLinkLoading(true);
    setTimeout(() => {
      setLinkLoading(false);
      setOpenUpdateModal(false);
    }, 2000);
  };

  const onDeactivateLink = (linkId) => {
    const updatedLinks = links.map((link) => {
      if (link.id === linkId) {
        return { ...link, enable: !link.enable };
      }
      return link;
    });

    setLinks(updatedLinks);
  };

  const handleDelete = () => {
    console.log(selectedLink);
    setLinkLoading(true);
    setTimeout(() => {
      const updatedLinks = links.filter((link) => link.id !== selectedLink.id);
      setLinks(updatedLinks);
      setLinkLoading(false);
      console.log('Suppression effectuée !');
      setConfirmationModal(false);
    }, 2000);

  };

  const onDeleteLink = (link) => {
    setSelectedLink(link);
    setConfirmationModal(true);
  };


  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, [])

  return (
    <div className="container mx-auto pt-10 font-grotesk">
      <WelcomeMessage username={user.fullname} />
      <div className="flex justify-between items-center py-10">
        <h2 className="text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl">
          Liens
        </h2>
        <div className="flex justify-between items-center gap-3">
          <CreateNewLink
            openModal={openCreateModal}
            setOpenModal={setOpenCreateModal}
            createLink={createLink}
            loading={linkLoading}
          />

          <Button
            onClick={refreshLinks}
            isProcessing={loading}
            processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            disabled={loading}
          >
            {!loading && <HiOutlineRefresh className="mr-2 h-5 w-5" />} Actualiser
          </Button>
        </div>

      </div>
      <LinkList
        links={links}
        loading={loading}
        onUpdateLink={handleUpdateLink}
        onDeactivateLink={onDeactivateLink}
        onDeleteLink={onDeleteLink}
      />

      <UpdateLink
        openModal={openUpdateModal}
        setOpenModal={setOpenUpdateModal}
        updateLink={updateLink}
        loading={linkLoading}
        initialData={selectedLink}
      />
      <Confirmation
        openModal={confirmationModal}
        loading={linkLoading}
        setOpenModal={setConfirmationModal}
        message="Êtes-vous sûr de vouloir supprimer ?"
        onConfirm={handleDelete}
      />
    </div>

  )
};

export default Dashboard;