const useAuth = () => {
    // Récupérer les informations de l'utilisateur depuis le localStorage
    const storedUserData = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
  
    // Vérifier si les informations sont présentes
    const isAuthenticated = storedUserData && storedToken;
  
    if (isAuthenticated) {
      const user = JSON.parse(storedUserData);
  
      return {
        isAuthenticated: true,
        user: {
          email: user.email,
          token: storedToken,
          fullname: user.fullname,
          username: user.username,
        },
      };
    } else {
      return {
        isAuthenticated: false,
        user: null,
      };
    }
  };

  const formatDate = (dateString) => {
    const options = {
      day: 'numeric',
      month: 'short', // Utilisation de 'short' pour obtenir le format abrégé du mois
      year: '2-digit', // Utilisation de '2-digit' pour obtenir les deux derniers chiffres de l'année
      hour: 'numeric',
      minute: 'numeric',
    };
  
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };
  
  export { useAuth, formatDate };
  