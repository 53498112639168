import { useLocation } from "react-router-dom";
import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import WelcomeMessage from "../../components/Welcome";
import { useAuth } from "../../utils";
import curious from "../../assets/Curious-bro.png"

const Stats = () => {
  // const { id } = useParams();
  const location = useLocation();

  const { user } = useAuth();
  const linkData = location.state;

  return (
    <>

      <div className="container mx-auto pt-10 font-grotesk">
        <WelcomeMessage username={user.fullname} />

        <Breadcrumb aria-label="Default breadcrumb example">
          <Breadcrumb.Item href="/dashboard" icon={HiHome}>
            Liens
          </Breadcrumb.Item>
          <Breadcrumb.Item>Statistiques</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Statistiques : <mark className="px-2 text-white bg-cyan-600 rounded ">{linkData.short_url}</mark></h1>

        <p className="text-gray-500">Votre lien <a target='_blank' rel='noreferrer' href={`https://tr05tr.com/${linkData.short_url}`} className="inline-flex items-center font-medium text-cyan-600 hover:underline">
          {`https://tr05tr.com/${linkData.short_url}`}
          <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </a></p>

        <div className="shadow-lg border border-md mt-10 p-5">

          <img className="rounded-full h-auto max-w-lg mx-auto" src={curious} alt="empty stats" />

          <h5 className="mb-4 text-2xl text-center font-extrabold leading-none tracking-tight text-gray-900">Il n'y a pas de statistiques disponibles pour le moment.</h5>
          <p className="mb-6 text-lg text-center font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Attendez d'avoir un peu de trafic et revenez.</p>

        </div>
      </div>
    </>
  )
};

export default Stats;