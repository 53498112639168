import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { AiOutlineLoading } from 'react-icons/ai';

const Confirmation = ({ message, openModal, setOpenModal, onConfirm, loading }) => {

  return (
    <>
      <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {message}
            </h3>
            <div className="flex justify-center gap-4">
              <Button 
              isProcessing={loading}
              processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={loading}
              color="failure" 
              onClick={() => onConfirm()}>
                Oui, j'en suis sur
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Confirmation;