import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-100x100.png';
import { useState } from 'react';
import { HiMail, HiKey } from 'react-icons/hi';
import { AiOutlineLoading } from 'react-icons/ai';
import { toast } from "react-toastify"
// import { useDispatch } from 'react-redux';
// import { setUser } from '../../store/userSlice';
import { post } from "../../axiosConfig"




const Login = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate()
  console.log(process.env.REACT_APP_BACKEND_URL)


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [agree, setAgree] = useState(false)

  const [loading, setLoading] = useState(false)

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    await post("/auth/login", { username: email, password: password })
      .then((res) => {
        setLoading(false)
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify({
          username: res.data.username,
          fullname: res.data.full_name,
          email: res.data.email
        }));
        toast.success("Connecté avec succès.")
        navigate("/dashboard")
      })
      .catch(() => {
        setLoading(false)
      })
  };

  return (
    <div className='flex items-center justify-center pt-32 bg-gray'>
      <div className="max-w-xl w-full">
        <Link to="/">
          <div className='flex justify-center align-middle pb-10'>
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
            <span className="self-center whitespace-nowrap text-xl font-chakra">EasyLink</span>
          </div>
        </Link>
        <form className="flex flex-col max-w-xl p-2 md:p-10 lg:p-10 border rounded-lg shadow-lg gap-5 font-grotesk" onSubmit={(e) => loginUser(e)}>
          <div className='pb-3 border-b'>
            <h4 className='text-xl text-bold'>Connexion</h4>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email" value="Adresse e-mail ou Nom d'utilisateur" />
            </div>
            <TextInput id="email" type="text" placeholder="name@gmail.com ou name" rightIcon={HiMail} required shadow value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password" value="Mot de passe" />
            </div>
            <TextInput id="password" type="password" required shadow placeholder='********' rightIcon={HiKey} value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="flex justify-between items-center gap-2">
            <div className='flex gap-2'>

              <Checkbox id="agree" checked={agree} onChange={() => setAgree(!agree)} />
              <Label htmlFor="agree" className="flex">
                Se souvenir de moi
              </Label>
            </div>
            <div>
              <Link to="/forgot-password" className="text-sm text-cyan-600 hover:underline dark:text-cyan-500">
                Mot de passe oublié
              </Link>
            </div>
          </div>
          <Button
            type="submit"
            isProcessing={loading}
            processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            disabled={loading}
          >Se connecter</Button>

          <p className="text-gray-500 text-right">Vous n'avez pas encore de compte, <Link to="/register" className="inline-flex items-center font-medium text-cyan-600 dark:text-cyan-500 hover:underline">
            S'inscrire
            <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
            </svg>
          </Link></p>
        </form>
      </div>
    </div>
  );
};

export default Login;