import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 50000,
});

const get = (url, params) => {
  params = { ...params };
  const token = localStorage.getItem('token');
  return instance.get(url, { params, headers: token ? { Authorization: `Bearer ${token}` } : {} });
};

const post = (url, data) => {
  const token = localStorage.getItem('token');
  return instance.post(url, data, { headers: token ? { Authorization: `Bearer ${token}` } : {} });
};

const put = (url, data) => {
  const token = localStorage.getItem('token');
  return instance.put(url, data, { headers: token ? { Authorization: `Bearer ${token}` } : {} });
};

const del = (url, data) => {
  const token = localStorage.getItem('token');
  return instance.delete(url, { data, headers: token ? { Authorization: `Bearer ${token}` } : {} });
};

export { get, post, put, del };
