import { Button, Modal } from 'flowbite-react';
import LinkForm from './Form';
import { HiOutlinePlusCircle } from "react-icons/hi";


const CreateNewLink = ({ openModal, setOpenModal, loading, createLink }) => {

  return (
    <>

      <Button onClick={() => setOpenModal(true)}>
        <HiOutlinePlusCircle className="mr-2 h-5 w-5" />Créer
      </Button>
      <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
        <Modal.Header />
        <Modal.Body>
          <LinkForm
            onSubmit={createLink}
            loading={loading}
            mode="create"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}


export default CreateNewLink;