import { Button, Label, TextInput } from 'flowbite-react';
import { HiLink, HiCursorClick } from 'react-icons/hi';
import { AiOutlineLoading } from 'react-icons/ai';
import { useState } from 'react';

const LinkForm = ({ onSubmit, loading, initialData, mode }) => {
  const [originalUrl, setOriginalUrl] = useState(initialData?.original_url || '');
  const [shortUrl, setShortUrl] = useState(initialData?.short_url || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ originalUrl, shortUrl });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        {mode === 'create' ? 'Raccourcir le lien' : 'Modifier le lien'}

      </h3>
      <div>
        <div className="mb-2 flex">
          <HiLink className='mr-2 h-5 w-5' /> <Label htmlFor="original" value="Lien long" />
        </div>
        <TextInput
          id="original"
          placeholder="https://itnet-technologies.com"
          required
          shadow
          value={originalUrl}
          onChange={(e) => setOriginalUrl(e.target.value)}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <div className="mb-2 flex">
            <HiCursorClick className='mr-2 h-5 w-5' /> <Label htmlFor="short" value="Lien court" />
          </div>
          <TextInput
            id="short"
            placeholder="2023"
            addon="https://tr05tr.com/"
            required
            value={shortUrl}
            onChange={(e) => setShortUrl(e.target.value)}
            helperText={
              <>
                Laissez ce champ libre pour un lien généré aléatoirement.
              </>
            }
          />
        </div>
      </div>
      <div className="flex">
        <Button
          type="submit"
          className="w-full"
          isProcessing={loading}
          processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
          disabled={loading}
        >
          {mode === 'create' ? 'Réduire le lien' : 'Enregistrer les modifications'}
        </Button>
      </div>
    </form>
  );
};

export default LinkForm;
