import React from "react";
import { Button } from 'flowbite-react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div className="container mx-auto ">
        <div className="text-center pt-10 font-grotesk">
          <h1 className="mb-4 text-3xl md:text-6xl lg:text-8xl font-extrabold text-gray-900"><span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Réduisez vos liens,</span> <br />élargissez votre impact.</h1>
          <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Une solution simple pour des liens plus percutants. Personnalisez, mesurez l'impact et simplifiez votre présence en ligne en un clin d'œil.</p>
          <div className="flex justify-center pt-20 pb-32">
            <div className="mr-5">
              <Button pill type="a" href="/login">Commencer</Button>
            </div>
            <div><Button color="gray">Documentation</Button></div>
          </div>
        </div>
      </div>
      <div className="bg-cyan-700 border rounded-lg text-white mx-4 p-5">
        <h6 className="text-lg font-bold text-center font-grotesk">POUR PROFESSIONNELS ET PARTICULIERS</h6>
        <h2 className="text-4xl font-bold text-center font-grotesk">Des liens et pages personnalisés pour partager <br /> votre contenu et faciliter la vente.</h2>
        <div className="w-full flex justify-center">
          <div className="w-11/12 md:w-3/4 lg:w-1/2">
            <div className="flex flex-col md:flex-row justify-center pt-10">
              <div className="w-full md:w-1/2 pr-4 mb-8 md:mb-0">
                <ul className="max-w-md space-y-5 list-inside font-grotesk">
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Personnalisation des Liens
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Génération Automatique
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Suivi des Adresses IP
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Analyse du Nombre de Clics
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Sécurité Renforcée
                  </li>
                </ul>
              </div>
              <div className="w-full md:w-1/2 pr-4 mb-8 md:mb-0">
                <ul className="max-w-md space-y-5 list-inside font-grotesk">
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Intégration Facile
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Expiration des Liens
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Historique de Liens
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Statistiques Démographiques
                  </li>
                  <li className="flex items-center text-lg">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    Compatibilité Mobile
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full flex justify-center pt-10">
              <div className="w-1/2 mx-auto">
                <Link to="/register">
                  <Button color="cyan">Essayer maintenant
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Home;