import React from 'react';
import { FooterComponent } from './Footer';
import { Header } from './Header';

const LayoutWithHeaderFooter = ({ children }) => {
  return (
    <div className="w-screen bg-white">
      <Header />
      <div className="min-h-screen">
        {children}
      </div>
      <FooterComponent />
    </div>
  );
}

export default LayoutWithHeaderFooter;
