import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Login from '../pages/Auth/Login';
import LayoutWithHeaderFooter from '../components/LayoutWithHeaderFooter';
import LayoutWithoutHeaderFooter from '../components/Layout';
import Register from '../pages/Auth/Register';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Dashboard from '../pages/Dashboard';
// import About from '../pages/About';
// import Contact from '../pages/Contact';
// import PrivateRoute from '../components/PrivateRoute';
import RequireAuth from '../components/RequireAuth';
import Stats from '../pages/Dashboard/Stats';

const routes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<LayoutWithHeaderFooter children={<Home />} />}
        />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/login" element={<LayoutWithoutHeaderFooter children={<Login />} />} />
        <Route exact path="/register" element={<LayoutWithoutHeaderFooter children={<Register />} />} />
        <Route exact path="/forgot-password" element={<LayoutWithoutHeaderFooter children={<ForgotPassword />} />} />
        <Route path="/dashboard"
          element={<RequireAuth
            children={<LayoutWithHeaderFooter children={<Dashboard />} />}
          />}
        />
        <Route path="/dashboard/:id/stats"
          element={<RequireAuth
            children={<LayoutWithHeaderFooter children={<Stats />} />}
          />}
        />

        {/* <PrivateRoute
          path="/dashboard"
          component={<Dashboard />}
          layout={LayoutWithHeaderFooter}
          roles={["admin"]}
        /> */}
        {/* <PrivateRoute
          path="/contact"
          component={Contact}
        /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
