import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-100x100.png';
import { useState } from 'react';
import { HiMail, HiKey, HiUser } from 'react-icons/hi';
import { AiOutlineLoading } from 'react-icons/ai';
import { toast } from "react-toastify";
import { post } from "../../axiosConfig";



const Register = () => {

    const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [cpassword, setCpassword] = useState('')
  const [agree, setAgree] = useState(false)
  const [username, setUsername] = useState('')
  const [fullname, setFullName] = useState('')
  const [loading, setLoading] = useState(false)

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    await post("/auth/register", { username: username, password: password, email: email, full_name: fullname })
      .then((res) => {
        setLoading(false)
        toast.success("Inscription réussi.")
        navigate("/login")
      })
      .catch(() => {
        setLoading(false)
        toast.error("Une erreur s'est produite")
      })
  };


  return (
    <div className='flex items-center justify-center pt-10 bg-gray'>
      <div className="max-w-xl w-full">
        <Link to="/">
          <div className='flex justify-center align-middle pb-10'>
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
            <span className="self-center whitespace-nowrap text-xl font-chakra">EasyLink</span>
          </div>
        </Link>
        <form className="flex flex-col max-w-xl p-2 md:p-10 lg:p-10 border rounded-lg shadow-lg gap-5 font-grotesk" onSubmit={(e) => register(e)}>
          <div className='pb-3 border-b'>
            <h4 className='text-xl text-bold'>Inscription</h4>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="username" value="Nom d'utilisateur" />
            </div>
            <TextInput
              id="username"
              type="text"
              placeholder="User"
              rightIcon={HiUser}
              required
              shadow
              value={username}
              onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="fullname" value="Nom & Prénoms" />
            </div>
            <TextInput
              id="fullname"
              type="text"
              placeholder="John doe"
              rightIcon={HiUser}
              required
              shadow
              value={fullname}
              onChange={(e) => setFullName(e.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email" value="Adresse e-mail" />
            </div>
            <TextInput
              id="email"
              type="email"
              placeholder="name@gmail.com"
              rightIcon={HiMail}
              required
              shadow
              value={email}
              onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="password" value="Mot de passe" />
            </div>
            <TextInput
              id="password"
              type="password"
              required
              shadow
              placeholder='********'
              rightIcon={HiKey}
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="repeat-password" value="Confirmation de mot de passe" />
            </div>
            <TextInput
              id="repeat-password"
              type="password"
              required
              shadow
              placeholder='********'
              rightIcon={HiKey}
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)} />
          </div>
          <div className="flex justify-between items-center gap-2">
            <div className='flex gap-2'>

              <Checkbox
                id="agree"
                checked={agree}
                onChange={() => setAgree(!agree)} />
              <Label htmlFor="agree" className="flex">
                Se souvenir de moi
              </Label>
            </div>
          </div>
          <Button
            type="submit"
            isProcessing={loading}
            processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            disabled={loading}
          >
            S'inscrire
          </Button>

          <p className="text-gray-500 text-right">Vous avez déjà un compte, <Link to="/login" className="inline-flex items-center font-medium text-cyan-600 dark:text-cyan-500 hover:underline">
            Se connecter
            <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
            </svg>
          </Link></p>
        </form>
      </div>
    </div>
  );
};

export default Register;