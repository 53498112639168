const WelcomeMessage = ({ username }) => {
    return (
        <div className="pb-5 border-b">
            <div className="text-center">
                <h2 className="text-xl font-bold mb-4">Bienvenue, <span className="text-cyan-800">{username}</span></h2>
                <p className="text-gray-600">Nous sommes ravis de vous avoir parmi nous!</p>
            </div>
        </div>
    );
};

export default WelcomeMessage;