
import { ToggleSwitch, Dropdown, Pagination } from "flowbite-react";
import { formatDate } from "../../utils";
import { HiLink, HiTrash, HiPencil, HiViewGrid, HiDotsVertical, HiClipboardCopy } from "react-icons/hi";
import { useState } from "react";
import { Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";



const LinkList = ({ links, onUpdateLink, onDeleteLink, onDeactivateLink, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5

  const onPageChange = (page) => setCurrentPage(page);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedLinks = links.slice(startIndex, endIndex);

  const navigate = useNavigate();

  const copyToClipboard = (toCopy) => {
    const textArea = document.createElement('textarea');
    textArea.value = `${process.env.REACT_APP_BASE_URL}/${toCopy}`;

    document.body.appendChild(textArea);

    textArea.select();

    document.execCommand('copy');

    document.body.removeChild(textArea);

    toast.success("Lien copié")
  };

  return (<>
    {loading ? (
      <div className="flex justify-center items-center">
        <Spinner aria-label="Loading..." size="xl" />
      </div>
    ) : (
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-100 font-chakra">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  .
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  URL
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Clicks
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Créé le
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actif ?
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Options
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedLinks.map((link) => (
                <tr key={link.id} className="bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <HiLink />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm cursor-pointer text-gray-900" onClick={() => {
                      navigate(`/dashboard/${link.id}/stats`, { state: link });
                    }}>
                      {link.short_url}

                    </div>
                    <div className="text-xs text-gray-500">
                      {link.original_url.length > 30
                        ? `${link.original_url.slice(0, 30)}...`
                        : link.original_url}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{link.clicks}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{formatDate(link.created_at)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <ToggleSwitch checked={link.enable} sizing="sm"
                      onChange={() => onDeactivateLink(link.id)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <Dropdown label={<HiDotsVertical />} size="sm" arrowIcon={false}>
                    <Dropdown.Item icon={HiClipboardCopy} onClick={() => copyToClipboard(link.short_url)}>Copier</Dropdown.Item>
                      <Dropdown.Item icon={HiViewGrid}
                        // as={Link} to={{
                        //   pathname: `/dashboard/${link.id}/stats`,
                        //   state: { linkData: link }
                        // }}
                        onClick={() => {
                          navigate(`/dashboard/${link.id}/stats`, { state: link });
                        }}
                      >Statistiques</Dropdown.Item>
                      <Dropdown.Item icon={HiPencil} onClick={() => onUpdateLink(link)}>Modifier</Dropdown.Item>
                      <Dropdown.Item icon={HiTrash} onClick={() => onDeleteLink(link)}>Supprimer</Dropdown.Item>

                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
        <div className="flex justify-center mt-4">
          <Pagination
            layout="pagination"
            nextLabel="Suivant"
            previousLabel="Précedent"
            showIcons
            currentPage={currentPage}
            totalPages={Math.ceil(links.length / ITEMS_PER_PAGE)}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    )}
  </>
  );
};

export default LinkList;