import React from 'react';

const LayoutWithoutHeaderFooter = ({ children }) => {
  return (
    <div className='w-screen bg-white'>
      <div className="min-h-screen">
          {children}
        </div>
    </div>
  );
}

export default LayoutWithoutHeaderFooter;
