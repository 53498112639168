import React from "react";
import { Header } from "../components/Header";
import { FooterComponent } from "../components/Footer";


const About = () => {
  return (
    <div className="bg-white">
      <Header />
      <p>Welcome to About Page</p>
      <FooterComponent />
    </div>
  )
}

export default About;