import { Modal } from 'flowbite-react';
import LinkForm from './Form';

const UpdateLink = ({ openModal, setOpenModal, loading, initialData, updateLink }) => {
  return (
    <>
      <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
        <Modal.Header />
        <Modal.Body>
        <LinkForm
            onSubmit={updateLink}
            loading={loading}
            initialData={initialData}
            mode="update"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}


export default UpdateLink;