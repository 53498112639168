// import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo-100x100.png';
import { Button, Navbar } from 'flowbite-react';
// import { useSelector } from 'react-redux';
import { useAuth } from '../utils';
import { HiOutlineLogout } from "react-icons/hi";
import { useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';


export const Header = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [loading, setLoading] = useState(false);

  const disconnect = () => {
    console.log(user.token)
    setLoading(true);
    setTimeout(() => {
      localStorage.clear();
      setLoading(false);
      navigate("/login");

    }, 2000);
  }
  return (
    <Navbar fluid rounded className='font-chakra rounded-lg shadow'>
      <Navbar.Brand href="/">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
        <span className="self-center whitespace-nowrap text-xl">EasyLink</span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        {!isAuthenticated ? (
          <Link to="/login">
            <Button>Commencer</Button>
          </Link>
        ) : (
          <Button
            isProcessing={loading}
            processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
            disabled={loading}
            onClick={() => disconnect()
            }>Deconnexion <HiOutlineLogout className='ml-2 h-5 w-5' /></Button>
        )}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="/" active>
          Accueil
        </Navbar.Link>
        {/* <Navbar.Link href="/about">A propos</Navbar.Link>
        <Navbar.Link href="#">Services</Navbar.Link>
        <Navbar.Link href="#">Prix</Navbar.Link> */}
      </Navbar.Collapse>
    </Navbar>

  );
};
